import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import PowerScore from "components/Common/table/commons/PowerScore";

import InsightCard from "../../../components/InsightCard";
import ReachContent, { firstContainerStyles } from "../ReachContent";
import ReachContentHelpIcon from "../ReachContentHelpIcon";
import ReachContentIcon from "../ReachContentIcon";
import ReachPopUp from "../ReachPopUp";

import sendGAEvent from "utils/sendGAEvent";

import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faBoltCircle from "styles/icons/faBoltCircle";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...firstContainerStyles,
  data: {
    ...gStyles.avalonBold,
    fontSize: "1.5rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    color: "var(--color-neutral-d5)",
    textAlign: "center",
    margin: 0,
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  paraBold: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  button: {
    width: "max-content",
    border: `1px solid ${colours.borderGrey}`,
    padding: "0.5rem 1rem",
    borderRadius: 25,
    ...gStyles.avalonBold,
    fontSize: "1rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: "1rem auto 0 auto",
  },
};

const overrideStyles = {
  icon: {
    color: colours.primary,
    fontSize: "1rem",
  },
};

const newStyles = {
  centerScore: {
    padding: "0 1rem",
    height: 40,
    fontSize: 30,
    margin: "0.5rem auto",
    borderRadius: 150,
  },
  cell: {
    padding: 0,
    borderLeft: "none",
  },
};

const ReachPopUpStyles = {
  outer: {
    marginLeft: 0,
    marginTop: 0,

    [ScreenSizes.mdAndAbove]: {
      marginLeft: -260,
      bottom: 30,
    },
    [createBreakpoint({ min: 920 })]: {
      marginLeft: -138,
    },
  },
  triangle: {
    right: -10,
    left: "auto",

    [createBreakpoint({ min: 920 })]: {
      left: 0,
      right: 0,
    },
  },
};

const ReachPopUpWithWarningStyles = {
  outer: {
    marginLeft: 0,
    marginTop: 0,

    [ScreenSizes.mdAndAbove]: {
      marginLeft: -238,
      bottom: 30,
    },
    [createBreakpoint({ min: 920 })]: {
      marginLeft: -120,
      marginTop: -260,
    },
  },
  triangle: {
    right: -10,
    left: "auto",

    [createBreakpoint({ min: 920 })]: {
      left: 0,
      right: 0,
    },
  },
};

const overrideCardStyles = {
  cardContainer: {
    borderRadius: 0,

    [ScreenSizes.mdAndAbove]: {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },

    [createBreakpoint({ min: 920 })]: {
      borderBottomLeftRadius: "0.75rem",
      borderTopRightRadius: 0,
      borderBottom: `1px solid var(--color-neutral-d95)`,
    },
  },
};

const overrideCardStylesWithWarning = {
  cardContainer: {
    borderRadius: 0,

    [ScreenSizes.mdAndAbove]: {
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },

    [createBreakpoint({ min: 920 })]: {
      borderBottomLeftRadius: "0.75rem",
      borderTopRightRadius: 0,
    },
  },
};

export default function Powerscore(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId, showPopUp, handlePopUp, showWarning, powerScore } = props;

  const { getWindowWidth } = useWindowSize();

  const currentSize = getWindowWidth();
  const onMobile = currentSize < 640;

  const activelySelected = showPopUp?.active === "powerscore";

  const onClick = useCallback(() => {
    if (activelySelected) {
      handlePopUp(null);
    } else {
      sendGAEvent({
        action: "infoIconClick",
        context: "Reach Insights",
        element: "Power Score",
        page: "Podcast Insights",
        podcastId,
      });
      handlePopUp("powerscore");
    }
  }, [activelySelected, handlePopUp, podcastId]);

  const wrapperRef = useOutsideClickHandler(() => handlePopUp(null));

  return (
    <InsightCard
      styles={showWarning ? overrideCardStylesWithWarning : overrideCardStyles}
    >
      <ReachContent>
        <div className={css(styles.firstContainer)}>
          <ReachContentIcon
            dataid="powerIcon"
            icon={faBoltCircle}
            styles={overrideStyles}
          />
          <h2 className={css(styles.title)}>Power Score&trade;</h2>
          <ReachContentHelpIcon
            onClick={onClick}
            show={activelySelected}
            showWarning={showWarning}
          >
            {activelySelected && (
              <ReachPopUp
                styles={
                  showWarning ? ReachPopUpWithWarningStyles : ReachPopUpStyles
                }
              >
                <h2 className={css(styles.popUpTitle)}>Power Score</h2>
                <p className={css(styles.para)}>
                  Our metric indicating this podcast’s overall popularity and
                  influence, based on Podchaser’s unique algorithm that looks at
                  30+ different data points.
                </p>
                {onMobile ? (
                  <a
                    className={css(styles.paraBold)}
                    href="https://support.podchaser.com/en/articles/5506431-what-is-a-power-score"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more &nbsp;&nbsp;
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      dataid="expand"
                    />
                  </a>
                ) : (
                  <p className={css(styles.para)}>
                    Learn more about
                    <a
                      ref={wrapperRef}
                      className={css(styles.link)}
                      href="https://support.podchaser.com/en/articles/5506431-what-is-a-power-score"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Power Score&trade;
                    </a>
                  </p>
                )}
                {onMobile && (
                  <button onClick={onClick} className={css(styles.button)}>
                    Got It
                  </button>
                )}
              </ReachPopUp>
            )}
          </ReachContentHelpIcon>
        </div>
        <PowerScore
          podcastId={podcastId}
          score={powerScore}
          styles={newStyles}
        />
      </ReachContent>
    </InsightCard>
  );
}

Powerscore.propTypes = {
  podcastId: PropTypes.number,
  handlePopUp: PropTypes.func,
  showPopUp: PropTypes.object,
  showWarning: PropTypes.bool,
};

Powerscore.defaultProps = {
  podcastId: null,
};
