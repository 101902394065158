import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  rightContentDesktop: {
    display: "none",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "baseline",
    justifyContent: "center",

    [ScreenSizes.lgAndAbove]: {
      display: "flex",
      flexDirection: "column",

      alignItems: "flex-end",
      justifyContent: "flex-start",
    },

    [ScreenSizes.xlAndAbove]: {
      marginTop: 0,
      alignItems: "flex-end",
      flexDirection: "column",
    },

    [ScreenSizes.xxlAndAbove]: {
      marginTop: 0,
      alignItems: "center",
      flexDirection: "row",
    },
  },
};

function ListHeaderRight(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.rightContentDesktop)}>{props.children}</div>
  );
}

export default ListHeaderRight;
