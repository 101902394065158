import { useCallback } from "react";

import paginationActions from "actions/pagination";
import { selectSearchTerm } from "selectors/pagination";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

function useSearchTerm(props) {
  const { listKey, eventContext } = props;

  const { setSearchTerm } = useActionCreators({
    setSearchTerm: paginationActions.setSearchTerm,
  });

  const term = useReduxState(
    (state) => selectSearchTerm(state, { key: listKey }),
    [listKey]
  );

  const handleChange = useCallback(
    (value) => {
      function onSearchTermRequest() {
        sendGAEvent({
          action: "searchList",
          term,
          ...eventContext,
        });
      }

      const trimmedValue = value?.trim();
      if (listKey) {
        setSearchTerm(listKey, trimmedValue, onSearchTermRequest);
      }
    },
    [eventContext, listKey, setSearchTerm, term]
  );

  return { term, handleChange };
}

export default useSearchTerm;
